@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: white;
  font-family: "Montserrat", system-ui;
}

.body-size {
  max-width: 1950px;
}

.font-roboto {
  font-family: "Roboto", sans-serif;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
}

@layer utilities {
  .text-outline {
    color: transparent;
    -webkit-text-stroke: 1px rgba(0, 0, 255, 0.1); /* Adjust stroke width and color as needed */
  }
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.animate-marquee {
  display: inline-block;
  white-space: nowrap;
  animation: marquee 20s linear infinite;
}

.animated-card:hover{
  transition: all 0.3s ease-in-out;

}
.test{
  z-index: 10;
  height: 150px;
  
}
.list{
  opacity: 0;
}

.animated-card-child::after{
  content: ' ';
  display: none;
  position: absolute;
  height: 100%;
  width: 100%;
z-index: 1;
background: linear-gradient(rgba(0, 0, 0, 0.4),rgba(110, 32, 211, 0.4),rgba(111, 0, 255, 0.4),rgba(0, 0, 0, 0.6));
}


.animated-card-child:hover::after{
    display: block;
}

.animated-card:hover .test{
  transition: all 0.5s ease-in-out;
  height: 100%;
}
.animated-card:hover .list{
  transition: all 0.5s ease-in-out;
 opacity: 1;
}
.animated-card:hover .animated-arrow   {
transition: all 0.3s ease-in-out;
background-color: white;
height: 110px;
width: 106px;
z-index: 2;
}

.animated-card:hover .arrow-white   {
transition: opacity 0.9s ease;
opacity: 0;
}
.animated-card:hover .arrow-purple   {

transition: all 0.9s ease;
opacity: 1;
margin-right:50px ;
}


.top-left-animated{
  transform: translateX(0);
    animation-name: transitionleft;
      animation-timing-function: linear;
  animation-duration: 4s;
    animation-iteration-count: infinite;
animation-duration: alternate;

}
@keyframes transitionleft {
   0% {transform:translateX(0px)}
  25%{transform:translateX(-200px)}
  50%{transform:translateX(-400px)}
  75%{transform:translateX(-600px)}
  100%{transform:translateX(0px)}
  
}

.top-right-animated{
  transform: translateX(0);
    animation-name: transitionright;
      animation-timing-function: linear;
  animation-duration: 4s;
    animation-iteration-count: infinite;
animation-duration: alternate;

}
@keyframes transitionright {
   0% {transform:translateX(0px)}
  25%{transform:translateX(200px)}
  50%{transform:translateX(400px)}
  75%{transform:translateX(600px)}
  100%{transform:translateX(0px)}
  
}
.tag-wrap {
  filter: drop-shadow(0px 0px 14px rgba(24, 50, 0, 0.5));}

.slant-right {
  /* position: relative; */
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 85% 100%, 0% 100%);
  
}
